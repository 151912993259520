import React, { useState } from 'react';
import Drawer from "@material-ui/core/Drawer";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Link } from "react-router-dom";

const mobileDrawerOptions = [
  {
    id: 0,
    text: "Shop",
    route: "/shop/6gkSICnXAzNBlQWPjPuZ",
  },
  // {
  //   id: 1,
  //   text: "Blog",
  //   route: "/blogs/0000AZdtmWXt19e88kqR",
  // },
  {
    id: 2,
    text: "About Us",
    route: "/about-us",
  },
  {
    id: 3,
    text: "Contact Us",
    route: "/contact-us",
  },
];

const HEIGHT_OF_ONE_FEATURE = 31.2;

const NavbarDrawer = ({ setOpen, classes, navHandler, serviceOptions, isOpen }) => {

  const [featuresExpanded, setFeaturesExpanded] = useState(false);

  let angle = "0";
  let height = "0px";
  if (featuresExpanded) {
    angle = "180deg";
    height = `${HEIGHT_OF_ONE_FEATURE * serviceOptions.length}px`;
  }
  return <Drawer
    transitionDuration={500}
    anchor="left"
    open={isOpen}
    onClose={() => setOpen(false)}
    className={classes.drawer}
  >
    <div className="heading">Menu</div>
    <div className="drawer-content-container">
      <div>
        <div
          className="drawer-root-option"
          onClick={() => setFeaturesExpanded(!featuresExpanded)}
        >
          Services
        <KeyboardArrowDownIcon
            style={{ transform: `rotate(${angle})` }}
          />
        </div>
        <div className="service-options-container" style={{ height }}>
          {serviceOptions.map((o) => (
            <Link
              key={o.id}
              className="drawer-level-1-option"
              to={o.route}
              onClick={navHandler}
            >
              {o.text}
            </Link>
          ))}
        </div>
      </div>
      {mobileDrawerOptions.map((o) => (
        <Link
          key={o.id}
          className="drawer-root-option"
          to={o.route}
          onClick={navHandler}
        >
          {o.text}
        </Link>
      ))}
    </div>
  </Drawer>;
};

export default NavbarDrawer;